@import 'assets/scss/mixins-and-vars';

.layout {
    display: flex;

    &-main,
    &-aside {
        flex: 1;
    }

    &-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        flex: 1 1 100%;
        padding-top: 25px;
        max-width: calc(min(100% - #{$layoutAsideWidthFull}, #{$layoutMainWidth}));
        background-color: $color-bg-default;
        transition: 0.3s;
        z-index: 2;

        &__container {
            flex: 1;
            padding-left: 50px;
            padding-right: 50px;
            padding-bottom: 10px;
        }
    }

    &-aside {
        flex: none;
        width: #{$layoutAsideWidthFull};
        padding-top: 175px;
        padding-bottom: 69px;
        background-color: $color-bg-brand;
        background-image: url('../../assets/img/logo-aside.svg');
        background-repeat: no-repeat;
        background-position: center 20px;
        background-size: auto 78px;
        transition: 0.3s;

        &__nav {
            padding-bottom: 30px;
            border-top: 1px solid $aside-border-color;
            transition: opacity 0.1s;
        }

        &__action {
            position: fixed;
            bottom: 0;
            left: 0;
            width: #{$layoutAsideWidthFull};
            border-top: 1px solid $aside-border-color;
            background-color: $color-bg-brand;
            transition: 0.3s;
            z-index: 100;
        }

        &__toggler {
            width: 100%;
            min-height: 17px;
            padding: 25px 43px 25px 24px;
            border: none;
            background: none;
            font-size: 12px;
            color: $color-regular;
            cursor: pointer;

            &-text {
                position: relative;
                display: block;
                height: 18px;
                padding-left: 30px;
                white-space: nowrap;
                transition: font-size 0.3s;

                &::before {
                    @include icon-mask($maskImage: url('../../assets/img/icon-arrow-toggle-left.svg'));
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                    background-color: $color-regular;
                }
            }
        }
    }

    &._aside-collapsed &-aside {
        cursor: pointer;
        font-size: 0;
        background-size: 30px auto;
        background-position: right 5px top 20px;
        margin-left: calc(#{$layoutAsideWidthCollapsed} - #{$layoutAsideWidthFull});

        &__nav {
            opacity: 0;
            pointer-events: none;
        }

        &__toggler {
            padding-left: 10px;
            padding-right: 10px;
        }

        &__toggler-text {
            padding-left: 18px;
            color: transparent;
            font-size: 0;

            &::before {
                transform: translateY(-50%) rotate(180deg);
                left: auto;
                right: -8px;
            }
        }

        &__action {
            left: calc(#{$layoutAsideWidthCollapsed} - #{$layoutAsideWidthFull});
        }
    }

    &._aside-collapsed &-main {
        flex-shrink: 0;
        max-width: calc(min(100% - #{$layoutAsideWidthCollapsed}, #{$layoutMainWidth} + #{$layoutAsideWidthFull} - #{$layoutAsideWidthCollapsed}));
    }

    &-public {
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 100vh;
        }

        &__body {
            flex: 1;
        }
    }
}
