@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-Regular.woff2') format('woff2'), url('Jost/Jost-Regular.woff') format('woff');
    font-weight: 1;
    font-style: normal;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-Thin.woff2') format('woff2'), url('Jost/Jost-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-ThinItalic.woff2') format('woff2'), url('Jost/Jost-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-Italic.woff2') format('woff2'), url('Jost/Jost-Italic.woff') format('woff');
    font-weight: 1;
    font-style: italic;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-ExtraLight.woff2') format('woff2'), url('Jost/Jost-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-ExtraLightItalic.woff2') format('woff2'), url('Jost/Jost-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-Light.woff2') format('woff2'), url('Jost/Jost-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-LightItalic.woff2') format('woff2'), url('Jost/Jost-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-Regular.woff2') format('woff2'), url('Jost/Jost-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-Italic.woff2') format('woff2'), url('Jost/Jost-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-Medium.woff2') format('woff2'), url('Jost/Jost-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-MediumItalic.woff2') format('woff2'), url('Jost/Jost-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-SemiBold.woff2') format('woff2'), url('Jost/Jost-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-SemiBoldItalic.woff2') format('woff2'), url('Jost/Jost-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-Bold.woff2') format('woff2'), url('Jost/Jost-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-BoldItalic.woff2') format('woff2'), url('Jost/Jost-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-ExtraBold.woff2') format('woff2'), url('Jost/Jost-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-ExtraBoldItalic.woff2') format('woff2'), url('Jost/Jost-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-Black.woff2') format('woff2'), url('Jost/Jost-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'Jost';
    src: url('Jost/Jost-BlackItalic.woff2') format('woff2'), url('Jost/Jost-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}
