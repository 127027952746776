@import 'assets/scss/mixins-and-vars';

.nav {
    &__configurator {
        height: 85px;
        border-bottom: 1px solid $aside-border-color;

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        &-container {
            height: 100%;
        }
    }

    &__select {
        height: 100%;
        font-size: 14px;
        font-weight: 500;

        .select__control {
            height: 100%;
            padding-left: 14px;
            padding-right: 10px;
            border: none;
            border-radius: 0;
            color: $aside-color-regular;
            box-shadow: none;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                background-color: $aside-color-highlight;
            }
        }

        .select__indicator {
            align-self: center;
        }

        .select__indicator svg path {
            fill: $aside-color-regular;
        }

        .select__menu {
            margin-top: 1px;
            background-color: $aside-color-highlight;
            border: none;
            border-radius: 0;
            font-size: 13px;
            color: $aside-color-regular;
        }

        .select__option {
            padding-left: 24px;
            padding-right: 20px;
            background-color: $aside-color-highlight;
            transition: 0.2s;

            &:hover {
                background-color: $color-light;
            }
        }

        .select__single-value {
            white-space: normal;
        }

        &.nav__app {
            .select__control--is-disabled {
                background-color: transparent;

                .select__indicators {
                    display: none;
                }
            }
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        &._sub {
            margin-top: 0;
        }

        &._sub &-item-link {
            padding-left: 37px;
        }

        &-item {
            &._parent._products._fixed {
                position: fixed;
                top: 0;
                left: 0;
                width: 190px;
            }

            &-link {
                display: block;
                padding: 6px 20px 6px 24px;
                font-size: 14px;
                font-weight: 500;
                text-decoration: none;
                color: $aside-color-regular;
                transition: background-color 0.1s;

                &:hover {
                    background-color: #afafaf;
                }

                &._active {
                    background-color: $aside-color-highlight;
                }

                &._active-parent {
                    color: $aside-color-highlight;

                    &:hover {
                        color: $aside-color-regular;
                    }
                }
            }
        }

        &-item._active &-link {
            color: $aside-color-highlight;
        }
    }

    .alert-danger {
        margin: 10px 10px 0;
    }
}
