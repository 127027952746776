@import './fonts';

$font-regular: Jost, sans-serif;
$font-size-default: 12px;

$color-bg-default: #fff;
$color-bg-brand: #2d2d2d;
$color-regular: #7d7d7d;
$color-regular-active: #606060;
$color-light: #cfcfcf;
$color-danger: #d91745;

$color-line: #ececec;

$aside-border-color: #383838;
$aside-color-regular: #606060;
$aside-color-highlight: #afafaf;

$color-accent: #1890ff;
$color-accent-hover: #005aae;
$btn-color: #fff;
$btn-border-radius: 4px;

$color-disabled-bg: #fafafa;
$color-disabled: #545454;

$color-alert-danger-text: #721c24;
$color-alert-danger-bg: #f8d7da;
$color-alert-danger-border: #f5c6cb;

$color-alert-warning-text: #856404;
$color-alert-warning-bg: #fff3cd;
$color-alert-warning-border: #ffeeba;

$color-alert-success-text: #155724;
$color-alert-success-bg: #d4edda;
$color-alert-success-border: #c3e6cb;

$color-alert-info-text: #0c5460;
$color-alert-info-bg: #d1ecf1;
$color-alert-info-border: #bee5eb;

$layoutWidth: '1920px';
$layoutAsideWidthFull: '190px';
$layoutAsideWidthCollapsed: '38px';
$layoutMainWidth: 'calc(#{$layoutWidth} - #{$layoutAsideWidthFull})';

@mixin icon-mask($maskPosition: center, $maskSize: contain, $maskRepeat: no-repeat, $maskImage) {
    -webkit-mask-repeat: #{$maskRepeat};
    -webkit-mask-position: #{$maskPosition};
    -webkit-mask-size: #{$maskSize};
    -webkit-mask-image: #{$maskImage};
    mask-repeat: #{$maskRepeat};
    mask-position: #{$maskPosition};
    mask-size: #{$maskSize};
    mask-image: #{$maskImage};
}

@mixin custom-scrollbar(
    $x: false,
    $y: true,
    $size: 6px,
    $thumbBackground: rgba(0, 0, 0, 0.2),
    $thumbBorderRadius: 3px,
    $trackBackground: transparent
) {
    @if $x {
        overflow-x: auto;
    }

    @if $y {
        overflow-y: auto;
    }

    &::-webkit-scrollbar {
        width: #{$size};
        height: #{$size};
    }

    &::-webkit-scrollbar-button {
        display: none;
    }

    &::-webkit-scrollbar-track-piece {
        background: #{$trackBackground};
    }

    &::-webkit-scrollbar-thumb {
        background: #{$thumbBackground};
        border-radius: #{$thumbBorderRadius};
    }
}
