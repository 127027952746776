@import './mixins-and-vars';

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 5px 10px;
    border-radius: 4px;
    background: none;
    box-shadow: none;
    border: 1px solid;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    transition: 0.2s;

    &--primary {
        border-color: $color-accent;
        background-color: $color-accent;
        color: $color-bg-default;

        &:hover {
            border-color: $color-accent-hover;
            background-color: $color-accent-hover;
        }
    }

    &--secondary {
        border-color: $color-line;
        color: $color-regular;

        &:hover {
            border-color: $color-light;
            color: $color-regular-active;
        }
    }

    &--icon,
    &--icon-only {
        padding: 0;
        border-color: transparent;
        color: $color-regular;

        &:hover {
            border-color: transparent;
            color: $color-regular-active;

            .icon {
                fill: $color-accent;
            }
        }
    }

    &--text {
        height: auto;
        padding: 0;
        border: none;
        color: $color-accent;

        &:hover {
            color: $color-accent-hover;
        }
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
    }

    .icon {
        transition: 0.2s;
    }
}
