@import 'assets/scss/mixins-and-vars';

$f: '.footer';

#{$f} {
    flex: none;
    height: 70px;
    border-top: 1px solid transparent;

    &__container {
        height: 100%;
        padding: 10px 50px;
    }

    &__body {
        height: 100%;
    }

    &__links {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        height: 100%;
        margin-left: -10px;
        margin-right: -10px;
    }

    &__link {
        display: inline-block;
        padding: 5px 10px;
        font-size: 12px;
        font-weight: 500;
        color: $color-regular;
        transition: 0.2s;

        &:hover {
            color: $color-regular-active;
        }

        &:not(:last-child) {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(calc(-50% + 1px));
                width: 1px;
                height: 14px;
                background-color: $color-regular;
            }
        }
    }
}

.layout-public #{$f} {
    &__links {
        justify-content: center;
    }

    &__link {
        font-weight: normal;
        color: $color-accent;

        &:hover {
            color: $color-accent-hover;
        }

        &:not(:last-child)::after {
            background-color: $color-accent;
        }
    }
}
