@import 'assets/scss/mixins-and-vars';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    overflow: auto;
    z-index: 100;

    &-container {
        position: relative;
        display: flex;
        align-self: center;
        flex-direction: column;
        min-width: 320px;
        max-width: calc(100% - 30px);
        min-height: 100px;
        padding: 25px 30px;
        margin: 30px;
        border-radius: 4px;
        background-color: $color-bg-default;
        overflow: hidden;

        &._top {
            align-self: flex-start;
        }
    }

    &-head {
        flex: none;
        padding-right: 20px;
    }

    &-body {
        flex: 1;
    }

    &-title {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 600;
        color: $color-bg-brand;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-close {
        position: absolute;
        top: 10px;
        right: 10px;
        height: auto;
        opacity: 0.6;

        &:hover {
            opacity: 1;
        }
    }

    &-action {
        display: flex;
        margin-left: -7px;
        margin-right: -7px;
    }

    &-btn {
        flex: 1;
        margin-left: 7px;
        margin-right: 7px;
    }

    &__excel-upload {
        width: 400px;
        padding-top: 20px;
        font-size: 12px;
    }

    &__excel-upload-form {
        &-main {
            margin-bottom: 40px;
        }

        &-control {
            display: flex;
            align-items: center;
            margin-left: -7px;
            margin-right: -7px;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        &-file {
            &-btn,
            &-descr {
                flex: 1;
                margin-left: 7px;
                margin-right: 7px;
            }

            &-btn {
                max-width: calc(50% - 14px);

                .icon {
                    margin-right: 10px;
                    opacity: 0.6;
                }

                &:hover .icon {
                    opacity: 1;
                }
            }

            &-descr {
                font-size: 11px;
                overflow: hidden;

                .name {
                    display: inline-block;
                    vertical-align: bottom;
                    max-width: 80%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        &-error {
            color: $color-danger;
        }
    }

    &-message {
        &__container {
            padding-bottom: 20px;
        }
    }
}
