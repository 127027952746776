@import 'assets/scss/mixins-and-vars';

.scroll-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    opacity: 0.3;
    z-index: 3;

    &:hover {
        opacity: 1;
    }
}
