@import 'assets/scss/mixins-and-vars';

.header {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 32px;

    &__container {
        display: flex;
        justify-content: space-between;
        margin-left: -20px;
        margin-right: -20px;
    }

    &__breadcrumbs {
        flex: 1;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__user {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;

        &-head {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        &-bell {
            margin-left: 10px;

            .icon {
                @include icon-mask($maskImage: url('../../assets/img/icon-bell-fill.svg'), $maskSize: '16px');
                width: 16px;
                height: 16px;
                background-color: $color-regular;

                ._has-notifications & {
                    background-color: $color-danger;
                }
            }
        }

        &-body {
            display: none;
            position: absolute;
            top: 100%;
            right: 0;
            min-width: 150px;
            padding: 10px 20px;
            background-color: $color-bg-default;
            box-shadow: -10px 10px 10px $color-bg-default;
            border-radius: 5px;
            z-index: 1;
        }

        &:hover &-body {
            display: block;
        }

        &-name {
            margin-right: 8px;
            font-size: 14px;
            font-weight: 500;
            color: $color-light;
            transition: color 0.2s;
        }

        &:hover &-name {
            color: $color-regular;
        }

        &-avatar {
            @include icon-mask($maskImage: url('../../assets/img/icon-avatar.svg'), $maskSize: contain);
            width: 18px;
            height: 18px;
            background-color: $color-regular;
            transition: 0.2s;
        }

        &:hover &-avatar {
            background-color: $color-regular-active;
        }

        &-action {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &-links {
                margin-bottom: 15px;
            }

            &-link {
                justify-content: flex-start;
                white-space: nowrap;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                .icon {
                    @include icon-mask($maskImage: url('../../assets/img/icon-person-fill-up.svg'));
                    display: inline-block;
                    vertical-align: sub;
                    width: 16px;
                    height: 16px;
                    margin-left: 10px;
                    background-color: $color-accent;
                    transition: 0.2s;
                }

                &:hover .icon {
                    background-color: $color-accent-hover;
                }

                &._notifications {
                    display: flex;
                    justify-content: space-between;

                    .amount {
                        flex: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 16px;
                        height: 16px;
                        margin-left: 20px;
                        background-color: $color-danger;
                        border-radius: 50%;
                        color: $color-bg-default;
                        font-size: 7px;
                        opacity: 0.8;
                        transition: 0.2s;
                    }

                    &:hover .amount {
                        opacity: 1;
                    }
                }
            }
        }

        &-btn {
            padding-left: 35px;
            padding-right: 35px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }
    }
}
